import React from 'react';
import PropTypes from 'prop-types';
import Common from './Common';
import Hero from '../components/Hero/Hero';
import '../../i18n';

const TemplateWrapper = ({ hero, contentComponent, children }) => (
  <Common>
    <Hero {...hero} contentComponent={contentComponent} />
    <div>{children}</div>
  </Common>
);

TemplateWrapper.propTypes = {
  hero: PropTypes.object,
  contentComponent: PropTypes.element,
  children: PropTypes.node,
};

export default TemplateWrapper;
