/* eslint-disable camelcase */
import de from './landing.de.yaml';
import en from './landing.en.yaml';
import en_US from './landing.en_US.yaml';
import es from './landing.es.yaml';
import ga from './landing.ga.yaml';
import it from './landing.it.yaml';
import pt from './landing.pt.yaml';
import fr from './landing.fr.yaml';

export default {
  de,
  en,
  en_US,
  es,
  ga,
  it,
  pt,
  fr,
};
