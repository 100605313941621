const pop = {
  50: '#fce2ec',
  100: '#f8b6d0',
  200: '#f486b0',
  300: '#f05291',
  400: '#ed1e78',
  500: '#ea005f',
  600: '#da005c',
  700: '#c40058',
  800: '#af0054',
  900: '#89004e',
  A100: '#ff80ab',
  A200: '#ff4081',
  A400: '#f50057',
  A700: '#c51162',
};

export default pop;
