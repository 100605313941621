import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';

import Layout from '../layouts/Landing';
// import BlogRoll from '../components/BlogRoll/BlogRoll';

import { HTMLContent } from '../components/Content';
// import Section from '../components/Section';

// eslint-disable-next-line
export const IndexPageTemplate = () => (
  <div>
    {/* <Section className={classes.section}>
      <Box mb={3} fontSize={40} fontWeight="bold">
        Latest stories
      </Box>
      <BlogRoll />
      <Box display="flex" justifyContent="center" mt={6}>
        <Link to="/blog">
          <Button variant="outlined" color="primary">Read more</Button>
        </Link>
      </Box>
    </Section> */}
  </div>
);

IndexPageTemplate.propTypes = {
  classes: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.mdx;
  return (
    <Layout
      contentComponent={HTMLContent}
      hero={frontmatter.hero}
    >
      <IndexPageTemplate />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    mdx(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero {
          title
          subtitle
          cta
          cta2
        }
      }
    }
  }
`;
