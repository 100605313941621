/* eslint-disable camelcase */
/**
 * Switches between different translations of a file when the language changes
 */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

export default function useContentLanguageSwitcher({
  content: {
    en, en_US, es, pt, fr, ga, it, de,
  } = {},
}) {
  const { t } = useTranslation();
  const [data, setData] = useState(en);

  useEffect(() => {
    const currentLanguage = i18n.language;

    switch (currentLanguage) {
      case 'en':
        setData(en);
        break;
      case 'en-US':
        setData(en_US);
        break;
      case 'es':
        setData(es);
        break;
      case 'fr':
        setData(fr);
        break;
      case 'pt':
        setData(pt);
        break;
      case 'de':
        setData(de);
        break;
      case 'it':
        setData(it);
        break;
      case 'ga':
        setData(ga);
        break;
      default:
        setData(en);
    }
  }, [t]);

  return [data];
}
