import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  useTheme, Typography, Button, Grid, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/lazy';
import ResponsiveDialog from '@fathomtech/nualang-ui-components/dist/Dialogs/ResponsiveDialog';
import Navbar from '../Navbar/Navbar';
import NualaPhone from '../../../static/img/nuala-phone.svg';
import Section from '../Section';
import Social from '../Social/Social';
import pop from '../../themes/ThemeProviders/colors/pop';
import content from '../../../content/landing/index';
import useContentLanguageSwitcher from '../../hooks/useContentLanguageSwitcher';
import config from '../../config';

const Header = styled('header')(() => ({
  width: '100vw',
  minHeight: '100%',
}));

const NualaPhoneMobileImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'block',
    maxHeight: '300px',
  },
  display: 'none',
  margin: 'auto',
}));

const ctaButton = () => ({
  minWidth: 250,
  marginBottom: '1rem',
  marginRight: '0.5rem',
});

const social = (theme) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: '2rem',
  },
  marginTop: '1rem',
});

const nualaPhone = (theme) => ({
  [theme.breakpoints.up('md')]: {
    backgroundImage: `url(${NualaPhone})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
  },
  height: 'calc(100vh - 64px)',
});

const CTAPrimaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(pop[400]),
  backgroundColor: pop[400],
  '&:hover': {
    backgroundColor: pop[600],
  },
}));

const CTASecondaryButton = styled(Button)(() => ({
  color: pop[400],
  border: `1px solid ${pop[400]}`,
  '&:hover': {
    border: `1px solid ${pop[400]}`,
    backgroundColor: 'rgba(131, 0, 161, 0.04)',
  },
}));

// const CTAPrimaryButton = withStyles((theme) => ({
//   root: {
//     color: theme.palette.getContrastText(pop[400]),
//     backgroundColor: pop[400],
//     '&:hover': {
//       backgroundColor: pop[600],
//     },
//   },
// }))(Button);

// const CTASecondaryButton = withStyles(() => ({
//   root: {
//     color: pop[400],
//     border: `1px solid ${pop[400]}`,
//     '&:hover': {
//       border: `1px solid ${pop[400]}`,
//       backgroundColor: 'rgba(131, 0, 161, 0.04)',
//     },
//   },
// }))(Button);

function Hero() {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [data] = useContentLanguageSwitcher({ content });
  const {
    title, subtitle, cta, cta2,
  } = data;
  return (
    <Header>
      <Navbar t={t} />
      <Section
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            paddingTop: '0px',
          },
          paddingTop: '30px',
          height: '100%',
        })}
      >
        <Grid
          container
          justifyContent="center"
        >
          <Grid
            item xs={12} sm={12} md={6}
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                textAlign: 'left',
              },
              textAlign: 'center',
              maxWidth: '700px',
            })}
          >
            <NualaPhoneMobileImg src={NualaPhone} alt="Nuala Phone" />
            <Typography
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  fontSize: '48px',
                  lineHeight: '65px',
                  marginTop: '8rem',
                },
                fontWeight: 'bold',
                fontSize: '30px',
                lineHeight: '40px',
                marginTop: '1rem',
                marginBottom: '1.5rem',
              })}
              variant="h1"
              component="h1"
            >
              {t(title)}
            </Typography>
            <Typography
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  fontSize: '24px',
                  lineHeight: '32px',
                },
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '24px',
                marginBottom: '1.5rem',
              })}
              variant="h4"
              component="h4"
            >
              {t(subtitle)}
            </Typography>
            <CTAPrimaryButton
              variant="contained"
              onClick={() => setIsVideoOpen(true)}
              sx={ctaButton}
            >
              {cta}
            </CTAPrimaryButton>
            <CTASecondaryButton
              variant="outlined"
              color="primary"
              sx={ctaButton}
              component={Link}
              to="/educators"
            >
              {cta2}
            </CTASecondaryButton>
            <Box sx={social}>
              <Social/>
            </Box>
          </Grid>
          <Grid item xs={0} md={6} sx={nualaPhone}>
          </Grid>
        </Grid>
        <ResponsiveDialog
          open={isVideoOpen}
          handleClose={() => setIsVideoOpen(false)}
          handleSubmit={() => { window.location.href = `${config.APP_URL}/?action=signUp`; }}
          submitText={'Get Started'}
          maxWidth="md"
        >
          <ReactPlayer
            url='https://www.youtube.com/watch?v=CbF-x36_H_w'
            width='100%'
            height={fullScreen ? '100%' : '75vh'}
            controls
            playing={isVideoOpen}
          />
        </ResponsiveDialog>
      </Section>
    </Header>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cta: PropTypes.string,
  cta2: PropTypes.string,
  languages: PropTypes.string,
};

export default Hero;
